body {
  background-color: #eaeaea;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(30, 82, 141) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 139, 172) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.country-list {
  text-align: left;
}

.ui.text.container {
  font-size: 1rem;
  max-width: 1000px !important;
}

.ui.labeled.icon.menu .item {
  font-size: 0.9em;
}

.ui.multiple.dropdown > .label {
  font-size: 1em !important;
}

.icon-social-button {
  font-size: 1em !important;
  top: 0 !important;
}

i.icon,
i.icons {
  font-size: 1.2em;
  /* top: auto !important; */
}

.ui.menu .item > .floating.label {
  top: 0.2em;
  left: auto;
  right: auto;
  margin: 0 0 0 2.5em !important;
}

@media screen and (min-width: 700px) {
  i.icon,
  i.icons {
    font-size: 1.5em;
    /* top: auto !important; */
  }

  .ui.label {
    font-size: 10px !important;
  }

  .ui.ribbon.label {
    font-size: 1rem !important;
  }

  .ui.menu .item > .floating.label {
    top: auto;
    left: auto;
    right: auto;
    margin: -1.8em 0 0 1.1em !important;
    padding: 0;
    /* min-width: 1.5em;
    min-height: 1.5em;
    max-height: 1.5em;
    max-width: 1.5em; */
  }
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.ui.ribbon.label {
  z-index: 100;
}

.ui.image.label img {
  margin: -0.5833em -1em -0.5833em 0.5em;
  border-radius: 0.28571429rem;
}

.caddy-list {
  height: 35px !important;
}

.ui.label {
  font-size: 1rem;
}

.ui.feed > .event > .label {
  margin-top: 0.2em;
}

.ui.feed > .event > .content .date {
  margin: 0.1rem 0 0;
}

.ui.feed > .event > .label + .content {
  margin: -0.1em 0 0.35714286em 1.14285714em;
}

.ui.multiple.dropdown > .text {
  line-height: 1em;
}

.ui.multiple.dropdown {
  padding: auto;
}

.caddy-choice {
  font-size: 1.2em !important;
}

.left-space {
  margin-left: 0.4em;
}

.number-input {
  height: 38px;
  width: 100%;
  margin: 0.5em 0 1em 0;
}

.number-input > div:first-child {
  justify-content: space-evenly;
}

.number-input > div:first-child > div {
  width: 100%;
}

.number-input > div:first-child > div > input {
  text-align: center !important;
}

div[name='caddyLevel'] > .icon {
  margin-top: -0.2em !important;
}

.mobile-menu {
  /* --safe-area-inset-bottom: calc * (env(safe-area-inset-bottom) + 3px); */
  /* padding-bottom: var(--safe-area-inset-bottom); */
  /* height: calc(env(safe-area-inset-bottom) + 5em); */
  padding-bottom: calc(env(safe-area-inset-bottom) - 1em);
  /* margin-bottom: calc( env(safe-area-inset-bottom) + 1em); */
  /* padding-top: env(safe-area-inset-top); */
}

.react-datepicker__month-dropdown-container--select {
  margin: 0 5px;
}

.dark-grey-text {
  color: #4e4e4e;
}

.react-datepicker__month {
  margin: inherit !important;
}

.button-margin {
  margin: 10px !important;
}

.button-margin-top {
  margin-top: 10px !important;
}

.button-side-margin {
  margin: 0 10px !important;
}
